export const links = {
  baseURL: 'https://api.wetech.technology/',
  uploads: 'https://api.wetech.technology/uploads/',

  // baseURL: 'http://192.168.1.68:3000/',
  // uploads: 'http://192.168.1.68:3000/uploads/',
  home: {
    slideshow: 'slideshow',
    service: 'service',
    settings: 'setting/65de4f50e362d71f317f96eb',
    product: 'product',
    client: 'client',
    brand: 'brand',
    news: 'news',
    productsearch: 'productsearch',
    category: 'productcategory',
    photoGallery: 'photoGallery',
  },
  contact: 'contact',
};
