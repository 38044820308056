import React, { CSSProperties } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import CustomTextField from '../theme-elements/CustomTextField';
import CustomFormLabel from '../theme-elements/CustomFormLabel';
import ParentCard from '../../shared/ParentCard';
import axios from 'axios';
import { FileUploader } from 'react-drag-drop-files';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from 'notistack';
import Spinner from '../../../views/spinner/Spinner';
import './Dropzone.css';
import { links } from 'src/helpers/links';
import { useAuthChecker } from 'src/helpers/useAuthChecker';
import api from 'src/helpers/axios';

interface SectionWithDividerProps {
  title: string;
}

const SectionWithDivider: React.FC<SectionWithDividerProps> = ({ title }) => {
  return (
    <Box mt={2}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Divider />
    </Box>
  );
};

type CompanyData = {
  _id?: string | null;
  whatsappNumber: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  mapEmbed?: string | null;
  fbLink: string | null;
  instaLink: string | null;
  linkedInLink: string | null;
  twitterLink: string | null;
  aboutImage?: File; // file
  aboutDescription: string | null;
  aboutImageAlt: string | null;
  contactEmail: string | null;
  metaKeyword?: string | null;
  metaDescription?: string | null;
  metaImage?: File; // file
  aboutStoryText: string | null;
  aboutMissionText: string | null;
  yearsOfExperience: number | null | string;
  statsOneText: string | null;
  statsOneNumber: number | null;
  statsTwoText: string | null;
  statsTwoNumber: number | null;
  statsThreeText: string | null;
  statsThreeNumber: number | null;
  isActive?: 0 | 1 | null;
  isDeleted?: 0 | 1 | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  __v?: number | null;
  tiktokLink: string | null;
  topMesssage?: string | null;
  featuresTitle: string | null;
  featuresText1: string | null;
  featuresText2: string | null;
  featuresText3: string | null;
  featuresText4: string | null;
  featuresText1IconImage?: string | null;
  featuresText2IconImage?: string | null;
  featuresText3IconImage?: string | null;
  featuresText4IconImage?: string | null;
};

type Body = Omit<CompanyData, 'privacy' | 'terms'>;

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];

const selectedImageStyle: CSSProperties = {
  borderRadius: '10px',
  height: '70px',
  width: '90px',
};
const imageFieldStyle: CSSProperties = { width: '100%', textAlign: 'center' };
const iconStyle: CSSProperties = { fontSize: 48, color: '#333' };
const maxWidth: CSSProperties = { width: '100%' };

const SocialsSettingsFormReal = () => {
  useAuthChecker();

  const notistack = useSnackbar();

  const initialState: CompanyData = {
    whatsappNumber: null,
    email: null,
    phone: null,
    address: null,
    mapEmbed: null,
    fbLink: null,
    instaLink: null,
    linkedInLink: null,
    twitterLink: null,
    aboutImage: undefined,
    aboutDescription: null,
    aboutImageAlt: null,
    contactEmail: null,
    metaKeyword: null,
    metaDescription: null,
    metaImage: undefined,
    aboutStoryText: null,
    aboutMissionText: null,
    yearsOfExperience: null,
    statsOneText: null,
    statsOneNumber: null,
    statsTwoText: null,
    statsTwoNumber: null,
    statsThreeText: null,
    statsThreeNumber: null,
    tiktokLink: null,
    topMesssage: null,
    featuresTitle: null,
    featuresText1: null,
    featuresText2: null,
    featuresText3: null,
    featuresText4: null,
  };

  const [state, setState] = React.useState<CompanyData>(initialState);
  const [loadingItems, setLoadingItems] = React.useState(true);

  const throwToast = (title: string, isErr: boolean, isInfo: boolean) => {
    notistack.enqueueSnackbar(title, {
      variant: isErr ? 'error' : isInfo ? 'info' : 'default',
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom',
      },
    });
  };

  const getSettingsAPI = async ({ callback }: { callback: (args?: boolean) => void }) => {
    try {
      const { data } = await axios.get(`${links.baseURL}/api/setting/65de4f50e362d71f317f96eb`);
      if (data) {
        const { settingInstance } = data;

        if (settingInstance) {
          const {
            whatsappNumber,
            email,
            phone,
            address,
            mapEmbed,
            fbLink,
            instaLink,
            linkedInLink,
            twitterLink,
            aboutImage,
            aboutDescription,
            aboutImageAlt,
            contactEmail,
            metaKeyword,
            metaDescription,
            metaImage,
            aboutStoryText,
            aboutMissionText,
            yearsOfExperience,
            statsOneText,
            statsOneNumber,
            statsTwoText,
            statsTwoNumber,
            statsThreeText,
            statsThreeNumber,
            tiktokLink,
            topMesssage,
            featuresTitle,
            featuresText1,
            featuresText2,
            featuresText3,
            featuresText4,
          } = settingInstance;

          setState({
            whatsappNumber,
            email,
            phone,
            address,
            mapEmbed,
            fbLink,
            instaLink,
            linkedInLink,
            twitterLink,
            aboutImage,
            aboutDescription,
            aboutImageAlt,
            contactEmail,
            metaKeyword,
            metaDescription,
            metaImage,
            aboutStoryText,
            aboutMissionText,
            yearsOfExperience,
            statsOneText,
            statsOneNumber,
            statsTwoText,
            statsTwoNumber,
            statsThreeText,
            statsThreeNumber,
            tiktokLink,
            topMesssage,
            featuresTitle,
            featuresText1,
            featuresText2,
            featuresText3,
            featuresText4,
          });

          callback && callback(true);
        }
      }
    } catch (error) {
      throwToast('Error fetching your settings...', true, false);
      callback && callback(false);
    }
  };
  const fetchSetting = async () => {
    await getSettingsAPI({
      callback: () => {
        setLoadingItems(false);
      },
    });
  };

  React.useEffect(() => {
    setLoadingItems(true);
    fetchSetting();
  }, []);

  const handleChangeAboutImage = (file: any) => {
    setState((prevState) => ({
      ...prevState,
      aboutImage: file,
    }));
  };

  const handleChangeMetaImage = (file: any) => {
    setState((prevState) => ({
      ...prevState,
      metaImage: file,
    }));
  };

  const handleSubmit = async () => {
    setLoadingItems(true);
    if (
      !state.aboutDescription ||
      !state.aboutDescription ||
      !state.aboutImageAlt ||
      !state.aboutMissionText ||
      !state.aboutStoryText ||
      !state.address ||
      !state.contactEmail ||
      !state.email ||
      !state.fbLink ||
      !state.featuresText1 ||
      !state.featuresText2 ||
      !state.featuresText3 ||
      !state.featuresText4 ||
      !state.featuresTitle ||
      !state.mapEmbed ||
      !state.phone ||
      !state.statsOneNumber ||
      !state.statsOneText ||
      !state.statsTwoText ||
      !state.statsTwoNumber ||
      !state.statsThreeText ||
      !state.statsThreeNumber ||
      !state.topMesssage ||
      !state.yearsOfExperience ||
      !state.whatsappNumber
    ) {
      setLoadingItems(false);

      return notistack.enqueueSnackbar('Please fill out all the fields.', {
        variant: 'warning',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    }

    const body: Body = {
      whatsappNumber: state.whatsappNumber,
      email: state.email,
      phone: state.phone,
      address: state.address,
      mapEmbed: state.mapEmbed,
      fbLink: state.fbLink,
      instaLink: state.instaLink,
      linkedInLink: state.linkedInLink,
      twitterLink: state.twitterLink,
      aboutImage: state.aboutImage,
      aboutDescription: state.aboutDescription,
      aboutImageAlt: state.aboutImageAlt,
      contactEmail: state.contactEmail,
      metaKeyword: state.metaKeyword,
      metaDescription: state.metaDescription,
      metaImage: state.metaImage,
      aboutStoryText: state.aboutStoryText,
      aboutMissionText: state.aboutMissionText,
      yearsOfExperience: state.yearsOfExperience,
      statsOneText: state.statsOneText,
      statsOneNumber: state.statsOneNumber,
      statsTwoText: state.statsTwoText,
      statsTwoNumber: state.statsTwoNumber,
      statsThreeText: state.statsThreeText,
      statsThreeNumber: state.statsThreeNumber,
      tiktokLink: state.tiktokLink,
      topMesssage: state.topMesssage,
      featuresTitle: state.featuresTitle,
      featuresText1: state.featuresText1,
      featuresText2: state.featuresText2,
      featuresText3: state.featuresText3,
      featuresText4: state.featuresText4,
    };

    if (state.aboutImage instanceof File) {
      body.aboutImage = state.aboutImage;
    }

    if (state.metaImage instanceof File) {
      body.metaImage = state.metaImage;
    }

    await formDataRequest(`api/setting/65de4f50e362d71f317f96eb`, body, false, true);
    await getSettingsAPI({
      callback: () => {
        setLoadingItems(false);
      },
    });
  };

  const formDataRequest = async (
    url: string,
    data: Body,
    isPostRequest = true,
    showAlert = true,
  ) => {
    const method = isPostRequest ? 'post' : 'put';
    try {
      const response = await api[method](url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status >= 400) {
        if (showAlert) {
          notistack.enqueueSnackbar('Error uploading your file', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
        }
      } else {
        if (showAlert) {
          notistack.enqueueSnackbar('Process completed!', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });

          return response;
        }
      }
    } catch (error) {
      notistack.enqueueSnackbar('Error uploading your product', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
      console.log(error);

      return error;
    }
  };

  if (loadingItems)
    return (
      <ParentCard title="Loading...">
        <>
          <CustomFormLabel htmlFor="product-title">
            Loading... Weaving webs of code.
          </CustomFormLabel>
          <Spinner />
        </>
      </ParentCard>
    );
  else
    return (
      <ParentCard title={`Socials Settings Form`}>
        <form>
          <SectionWithDivider title="Socials" />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CustomFormLabel htmlFor="fbLink">Facebook Link</CustomFormLabel>
              <CustomTextField
                id="fbLink"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e: any) =>
                  setState((prev) => ({
                    ...prev,
                    fbLink: e.target.value,
                  }))
                }
                value={state.fbLink}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <CustomFormLabel htmlFor="instaLink">Instagram Link</CustomFormLabel>
              <CustomTextField
                id="instaLink"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e: any) =>
                  setState((prev) => ({
                    ...prev,
                    instaLink: e.target.value,
                  }))
                }
                value={state.instaLink}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <CustomFormLabel htmlFor="linkedInLink">LinkedIn Link</CustomFormLabel>
              <CustomTextField
                id="linkedInLink"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e: any) =>
                  setState((prev) => ({
                    ...prev,
                    linkedInLink: e.target.value,
                  }))
                }
                value={state.linkedInLink}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <CustomFormLabel htmlFor="tiktokLink">Tiktok Link</CustomFormLabel>
              <CustomTextField
                id="tiktokLink"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e: any) =>
                  setState((prev) => ({
                    ...prev,
                    tiktokLink: e.target.value,
                  }))
                }
                value={state.tiktokLink}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <CustomFormLabel htmlFor="twitterLink">X Link</CustomFormLabel>
              <CustomTextField
                id="twitterLink"
                type="text"
                variant="outlined"
                fullWidth
                onChange={(e: any) =>
                  setState((prev) => ({
                    ...prev,
                    twitterLink: e.target.value,
                  }))
                }
                value={state.twitterLink}
              />
            </Grid>
          </Grid>

          <Box mt={2} />

          <div>
            <Button onClick={() => handleSubmit()} color="primary" variant="contained">
              Update
            </Button>
          </div>
        </form>
      </ParentCard>
    );
};

export default SocialsSettingsFormReal;

{
  /* <CustomFormLabel htmlFor="yearsOfExperience">Years of experience</CustomFormLabel>
          <CustomTextField
            id="yearsOfExperience"
            type="number"
            variant="outlined"
            fullWidth
            onChange={(e: any) =>
              setState((prev) => ({
                ...prev,
                yearsOfExperience: e.target.value,
              }))
            }
            value={state.yearsOfExperience}
          /> */
}
