import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardHeader, CardContent, Divider, Box, IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { IconPlus } from '@tabler/icons';
import { useNavigate } from 'react-router';

type Props = {
  title: string;
  footer?: string | JSX.Element;
  children: JSX.Element;
  withAdd?: boolean;
  additionRoute?: string;
  navWithState?: string | undefined | null;
};

const ParentCard = ({
  title,
  children,
  footer,
  withAdd = false,
  additionRoute = '/',
  navWithState,
}: Props) => {
  const navigate = useNavigate();
  const customizer = useSelector((state: AppState) => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  return (
    <Card
      sx={{ padding: 0, border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none' }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? 'outlined' : undefined}
    >
      {withAdd ? (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <CardHeader title={title} />
          <Tooltip title="Create New">
            <IconButton
              sx={{ height: 50, width: 50 }}
              id="basic-button"
              aria-haspopup="true"
              onClick={() => {
                navWithState
                  ? navigate(`${additionRoute}`, {
                      state: { _id: navWithState },
                    })
                  : navigate(`${additionRoute}`);
              }}
            >
              <IconPlus width={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <CardHeader title={title} />
      )}

      <Divider />

      <CardContent>{children}</CardContent>
      {footer ? (
        <>
          <Divider />
          <Box p={3}>{footer}</Box>
        </>
      ) : (
        ''
      )}
    </Card>
  );
};

export default ParentCard;
