import { keyVal } from './config';
import { useNavigate } from 'react-router';

export const useAuthChecker = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem(keyVal.accessToken);

  if (!token) {
    navigate('/auth/login2');

    return false;
  } else {
    return true;
  }
};
