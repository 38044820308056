import React from 'react';
import { Grid } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import FeaturesSettingsForm from 'src/components/forms/form-layouts/FeaturesSettingsForm';
import SocialsSettingsFormReal from 'src/components/forms/form-layouts/SocialsSettingsForm';

const FormSettingsSocial = () => {
  return (
    <PageContainer title="Form Layouts" description="this is innerpage">
      <Grid spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <SocialsSettingsFormReal />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default FormSettingsSocial;
